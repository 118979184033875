<template>
  <div class="popup">
    <div class="popup__body">
      <div class="popup__wrap">
        <button class="popup__close" aria-label="close popup">close</button>
        <div class="popup__content" @click.stop>
          <div class="popup__header">
            <h2 class="popup__title">{{ t("order_a_call_back") }}</h2>
          </div>
          <div class="popup__main">
            <form class="popup__form">
              <fieldset
                class="popup__form--label"
                v-bind:class="{ error: v$.name.$error }"
              >
                <input
                  type="text"
                  name="name"
                  autocomplete="off"
                  v-model="state.name"
                  id="form-name"
                  required
                />
                <label for="form-name">{{ t("FIO") }}</label>
              </fieldset>
              <fieldset
                class="popup__form--label"
                v-bind:class="{ error: v$.email.$error }"
              >
                <input
                  type="email"
                  name="email"
                  v-model="state.email"
                  autocomplete="off"
                  id="form-email"
                  required
                />
                <label for="form-email">{{ t("your_contact_email") }}</label>
              </fieldset>
              <fieldset
                class="popup__form--label"
                v-bind:class="{ error: v$.tel.$error }"
              >
                <vue-tel-input
                  ref="myinput"
                  v-mask="mask"
                  v-model="state.tel"
                  :inputOptions="{
                    id: 'form-tel',
                    type: 'tel',
                    name: 'tel',
                    required: 'required',
                    autocomplete: 'off',
                  }"
                  @close="
                    state.tel = '+ ' + pref;
                    this.$refs.myinput.focus();
                  "
                  mode="international"
                  class="form-questions__input"
                  @country-changed="Test"
                ></vue-tel-input>
                <label for="form-tel">{{ t("tel") }}</label>
              </fieldset>
              <vue-recaptcha
                style="margin-top: 20px"
                v-if="showRecaptcha"
                siteKey="6LeGLsocAAAAAKonw1p8EnGzVRjXsefuUZOxS0zV"
                size="normal"
                theme="light"
                :tabindex="0"
                @verify="recaptchaVerified"
                @expire="recaptchaExpired"
                @fail="recaptchaFailed"
                ref="vueRecaptcha"
              >
              </vue-recaptcha>
              <button
                v-if="alertStatus === false"
                @click="submitForm"
                type="submit"
                class="popup__form--btn"
                aria-label="Відправити"
              >
                {{ t("push") }}
              </button>
              <div class="form-questions__alert" v-if="alertStatus === true">
                <p class="alert-style">
                  {{ t("wait_for_a_call_soon") }}
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useValidate from "@vuelidate/core";
import phoneCodes from "@/assets/phone-codes.json";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import { required, email } from "@vuelidate/validators";
import { reactive, computed } from "vue";
import axios from "axios";
import { useI18n } from "vue-i18n";
import { defineComponent } from "vue";
import vueRecaptcha from "vue3-recaptcha2";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const state = reactive({
      name: "",
      email: "",
      tel: "",
    });

    const rules = computed(() => {
      return {
        name: { required },
        email: { required, email },
        tel: { required },
      };
    });

    const v$ = useValidate(rules, state);

    return {
      t,
      state,
      v$,
    };
  },

  components: {
    VueTelInput,
    vueRecaptcha,
  },

  data() {
    return {
      codes: phoneCodes,
      mask: "+ ## (###) ###-##-##",
      pref: null,
      token: null,
      showRecaptcha: true,
      alertStatus: false,
      valueName: true,
      valueEmail: true,
      valueTel: true,
      "state.name": this.value,
      "state.email": this.value,
      "state.tel": this.value,
    };
  },

  methods: {
    Test(ev) {
      let codes = [];
      codes = this.codes.filter((element) => {
        if (element.cc === ev.iso2) {
          return element;
        }
      });
      this.mask = codes[0].mask;
      this.pref = ev.dialCode;
    },

    recaptchaVerified(response) {
      this.token = response;
    },
    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset();
    },
    recaptchaFailed() {},

    submitForm(e) {
      e.preventDefault();
      let self = this;
      //Если все поля верны отправляем запрос
      this.v$.$validate().then(function (promiseResult) {
        if (promiseResult) {
          if (self.token.length > 0) {
            self.postForm(self.state.name, self.state.email, self.state.tel);
          }
        }
      });
    },
    async postForm(name, email, tel) {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        await axios.post(
          process.env.VUE_APP_API + "/api/callback",
          {
            name: name,
            email: email,
            tel: tel,
          },
          config
        );
      } catch (e) {
        alert("Ошибка запроса");
      } finally {
        //Вызов аллерта
        this.alertStatus = true;

        //Очистка инпутов + убираем красную подсветку
        this.state.name = "";
        this.state.email = "";
        this.state.tel = "";

        this.v$.name = true;
        this.v$.email = true;
        this.v$.tel = true;
      }
    },
  },

  watch: {
    "state.name"(currentValue) {
      this.$emit("input", currentValue);
      if (currentValue.length > 0) {
        this.valueName = true;
      } else {
        this.valueName = false;
      }
    },
    "state.email"(currentValue) {
      this.$emit("input", currentValue);
      if (currentValue.length > 0) {
        this.valueEmail = true;
      } else {
        this.valueEmail = false;
      }
    },
    "state.tel"(currentValue) {
      this.$emit("input", currentValue);
      if (currentValue.length > 0) {
        this.valueTel = true;
      } else {
        this.valueTel = false;
      }
    },
  },

  mounted() {
    const vueTelInput = document.querySelectorAll(".popup .vue-tel-input");

    for (let index = 0; index < vueTelInput.length; index++) {
      const element = vueTelInput[index];
      const label = element.nextElementSibling;

      element.append(label);
    }
  },
});
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/_mixins.scss";

.alert-style {
  color: green;
  width: 300px;
  margin: 10px;
  border: 1px solid green;
  padding: 10px;
  text-align: center;
}

.popup {
  position: fixed;
  top: 100px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  display: flex;
  flex-direction: column;
  transition: all 0.3s linear;

  @media (max-width: 575.98px) {
    top: 0;
  }

  &.open {
    visibility: visible !important;
    opacity: 1;
  }

  &__body {
    position: relative;
    display: flex;
    margin-top: auto;
    flex: 1 1 auto;
    max-height: 100%;
    background: url("~@/assets/images/bg_call-back.jpg") no-repeat;
    background-size: cover;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(#1c1c1c, 0.7);
    }
  }

  &__wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 2;
    margin: 0 auto;
    padding: 70px 15px;

    @media (min-height: 700px) {
      justify-content: center;
    }

    @media (max-height: 699.98px) {
      overflow-y: auto;
    }
  }

  &__close {
    position: absolute;
    @include property("top", 45, 15, true, 768, 320);
    @include property("right", 300, 15, true, 1670, 1366);
    width: 41px;
    height: 41px;
    font-size: 0;
    background: url("~@/assets/images/close.svg") no-repeat;
    background-size: 50% 50%;
    background-position: center center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    text-align: center;
    @include property("font-size", 32, 24, true, 768, 320);
    line-height: calc(42 / 32);
    letter-spacing: 1.6px;
    color: #f9b80e;
    text-transform: uppercase;
  }

  &__main {
    max-width: 540px;
    width: 100%;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &--label {
      position: relative;
      width: 100%;
      margin-top: 44px;

      &.error {
        input {
          color: red;
          border-color: red;
        }

        label {
          color: red;
          top: 0;
        }
      }

      input {
        font-size: 18px;
        line-height: calc(23 / 18);
        font-style: italic;
        letter-spacing: 0.9px;
        color: #e1e1e1;
        padding: 7px 10px;
        width: 100%;
        border-bottom: 0.5px solid #e1e1e1;

        &:focus,
        &:valid {
          ~ label {
            top: -5px;
          }
        }
      }

      label {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
        font-size: 18px;
        line-height: calc(23 / 18);
        font-style: italic;
        letter-spacing: 0.9px;
        color: #e1e1e1;
        transition: all 0.3s linear;
        cursor: text;
        width: 100%;
        text-align: center;
      }
    }

    &--btn {
      color: #fff;
      padding: 9px 32px 11px;
      border: 1px solid #e1e1e1;
      border-radius: 5px;
      margin-left: 20px;
      margin-top: 50px;
      transition: all 0.3s linear;

      @media (hover: hover) {
        &:hover {
          color: #f9b80e;
          border-color: #f9b80e;
        }
      }
    }
  }
}
</style>
